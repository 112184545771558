export const shortHex = (hexString: string | undefined) => {
  return hexString ? `0x${hexString.substr(0, 10)}` : undefined;
};

export const formatXchPrice = (xchAmount: number, fullPrecision = false) => {
  if (fullPrecision) {
    return xchAmount + ' XCH';
  }
  if (xchAmount >= 1000) {
    return parseFloat(xchAmount.toFixed(0)).toLocaleString('en') + ' XCH';
  } else if (xchAmount >= 0.0001) {
    return parseFloat(xchAmount.toFixed(4)).toLocaleString('en') + ' XCH';
  } else {
    const mojoAmount = xchAmount * 10 ** 12;
    return new Intl.NumberFormat().format(mojoAmount) + ' mojo' + (mojoAmount > 1 ? 's' : '');
  }
};

export function mojosToXchString(mojos: string) {
  // The float precision of JavaScript is too bad to convert the large amount of mojos to XCH without rounding.
  // Therefore, a different approach is used: Handling it as string and placing a decimal point.

  const xchElements = [];
  const mojosElements = String(mojos).split('').reverse();

  let numDec = 12; // Conversion factor = 1/ (10^numDec)
  let decPlaced = false;

  for (const digit of mojosElements) {
    xchElements.push(digit);
    numDec--;
    if (!decPlaced && numDec === 0) {
      decPlaced = true;
      xchElements.push('.');
    }
  }

  if (!decPlaced) {
    for (let i = numDec; i > 0; i--) {
      xchElements.push('0');
    }
    xchElements.push('0.');
  } else if (numDec === 0) {
    xchElements.push('0');
  }

  return xchElements.reverse().join('');
}
